import { KeyValue } from "@angular/common";

export class BaseResponse {
    data: any;
    isSuccessFull: string | undefined;
    errorMessage: string | undefined;
    totalCount: number | undefined;
    totalPages: number | undefined;
}

export interface PageRequest {
    page: number;
    perPage: number;
    sort?: string | null;
    order?: string | null;
    filter?: string | null;
}

export interface ApiResponse<T> {
    isSuccessFull: boolean;
    errorCode: string;
    errorMessage: string;
    data: T;
}

export interface ApiPageResponse<T> extends ApiResponse<T> {
    totalPages: number;
    totalCount: number;
}

export interface TableDialogColumn {
    name: string;
    title: string;
    class?: string | null;
}

export interface DialogData {
    title: string;
    ok: string;
    cancel: string;
}

export interface TableDialogData<T> extends DialogData {
    columns: Array<TableDialogColumn>;
    data: Array<T>;
}

export interface StatusDialogData extends DialogData {
    placeholder: string;
    selected: KeyValue<number, string> | null;
    data: Array<KeyValue<number, string>> | [];
}

export class GroupListDrpViewModel {
    groupId: number;
    name: string;
    isActive: boolean;
    parentGroupId: number | null;
    isAssigned: boolean;
}

export enum FeatureEnum {
    '' = 0,
    'Notifications' = 1,
    'Content Approval' = 2,
    'Group Management' = 3,
    'Roles Management' = 4,
    'Users Management' = 5
}

export enum PermissionEnum { 'View', 'Create', 'Edit', 'Admin Edit', 'Override Approval' }

  export class AddUserGroupRequest {
    userName: string;
    createdDate:any;
    sharedGroups: GroupsId[];
  }
  
  export class GroupsId {
    groupId: number;
  } 

  export enum Role {
    NotificationsCreate="notifications create",
    NotificationsEdit='notifications edit',
    NotificationsView='notifications view',
    NotificationsAdminEdit='notifications admin edit',
    ContentApprovalEdit='content approval edit',
    ContentApprovalOverrideApproval='content approval override approval',
    ContentApprovalView='content approval view',
    UsersManagementCreate='users management create',
    UsersManagementEdit='users management edit',
    UsersManagementView='users management view',
    RolesManagementCreate='roles management create',
    RolesManagementEdit='roles management edit',
    RolesManagementView='roles management view',
    GroupManagementCreate='group management create',
    GroupManagementEdit='group management edit',
    GroupManagementView='group management view',

}