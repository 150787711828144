import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue = inputElement.value;
    const numericPattern = /^[0-9]*$/;

    if (!numericPattern.test(inputValue)) {
      inputElement.value = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    }
  }

}
