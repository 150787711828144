<!-- <div align="right">
    <button mat-icon-button class="btn-close p-2" aria-label="Close" title="Close" [mat-dialog-close]="true"
        align="right"></button>
</div> -->
<div class="mdc-dialog__title">
    <h2>{{title}}</h2>
</div>
<div mat-dialog-content class="pt-0 mt-0">
    <!-- <div class="title-header py-3" align="center">
        <h2>{{title}}</h2>
    </div> -->
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container *ngFor="let col of columns; let i = index" matColumnDef="{{col.name}}">
                <th mat-header-cell *matHeaderCellDef> {{col.title}} </th>
                <td mat-cell *matCellDef="let element" [ngClass]="[col.class ? element[col.class] : '']">
                    <!-- {{element[col.name]}}  -->
                    <div [innerHTML]="element[col.name]"></div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
    </div>
</div>  
<div class="mat-mdc-dialog-actions">
     <button mat-icon-button class="btn btn-avnet-outline"  [mat-dialog-close]="true"> close</button>
</div>