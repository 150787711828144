
<div appDynamicMeta></div>
<section class="content-wrapper" [class.no-login-page]="!common.IsLoggedIn()" id="myDIV" >
  <app-loader></app-loader>
  <app-nav-menu  *ngIf="common.IsLoggedIn()"></app-nav-menu>
  <div class="right-side-content">
     <app-header *ngIf="common.IsLoggedIn()"></app-header>
     <div class="main-content">
      <router-outlet>
      </router-outlet>
      <app-push-subscriber *ngIf="common.IsLoggedIn() && !common.isAdminUser() && !common.isHideSubScribeDialog()"></app-push-subscriber>
     <app-footer></app-footer>
   </div>
  </div>
   <app-pwa-installation-alert *ngIf="common.IsLoggedIn()"></app-pwa-installation-alert>
 </section> 