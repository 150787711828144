<div class="page-content">
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404" *ngIf="common.isAdminUser()" >
                <h3 >You don't have Admin Access</h3>
                <h1><span>4</span><span>0</span><span>3</span></h1>
                
            </div>  
            <div class="notfound-404" *ngIf="!common.isAdminUser()">
                <h3>Oops! access denied</h3>
                <h1><span>4</span><span>0</span><span>3</span></h1>
            </div> 
            <!-- <h2>we are sorry, but the page you requested was not found</h2> -->
        </div>
    </div>
</div>