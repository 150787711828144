import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { PushConfirmDialogComponent } from '../push-confirm-dialog/push-confirm-dialog.component';

@Component({
  selector: 'app-push-subscriber',
  templateUrl: './pushsubscriber.component.html',
  styleUrls: ['./pushsubscriber.component.css']
})

export class PushSubscriberComponent {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })

  };
  isSubAlertHide: boolean=false;
  private _subscription: PushSubscription;
  public operationName: string;
  constructor(
    private swPush: SwPush,
    private httpClient: HttpClient, public dialog: MatDialog, private common:CommonService) 
    {
      swPush.subscription.subscribe((subscription) => {
        this._subscription = subscription as any;
        this.operationName = (this._subscription === null) ? 'Subscribe' : 'Unsubscribe';

       // if not subscribed give subscription alert
        if (this.operationName == "Subscribe" && !this.isSubAlertHide)  {
          //this.promptForSubscription();
          this.openDialog();
        } else {
        } 

      });  
  };

  openDialog() {
    const dialogRef = this.dialog.open(PushConfirmDialogComponent, {
      data: { message: 'Do you want to subscribe to push notifications?' },
      position: { top: '40px'},
    
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => { 
      if (confirmed) {
        this.subscribe()
      }
    });
  }
  operation() {
    (this._subscription === null) ? this.subscribe() : this.unsubscribe(this._subscription.endpoint);
     this.isSubAlertHide=true;

  };

  private subscribe() {
    const user = this.common.getUser()
    this.httpClient.get(environment.apiURL + 'pushsubscription', { responseType: 'text' }).subscribe(publicKey => {
      this.swPush.requestSubscription({
        serverPublicKey: publicKey
      })
        .then(subscription => this.httpClient.post(environment.apiURL + 'pushsubscription', {userName:user.username,endPoint:subscription.endpoint,p256dh:this.arrayBufferToBase64(subscription.getKey('p256dh')),auth:this.arrayBufferToBase64(subscription.getKey('auth')),createdDate: this.common.CurrentUtcDatetime().toISOString()  }, this.httpOptions).subscribe(
          () => {   },
          error => console.error(error)
        ))
        .catch(error => console.error(error));
    }, error => console.error(error));

  };

  private unsubscribe(endpoint: any) {
    const user = this.common.getUser()
    this.swPush.unsubscribe()
      .then(() => this.httpClient.delete(environment.apiURL + 'pushsubscription?userName=' + user.username+'&endPoint='+encodeURIComponent(endpoint)).subscribe(
        () => { },
        error => console.error(error)
      ))
      .catch(error => console.error(error));
  }
  private promptForSubscription() {
    const promptMessage = 'Do you want to subscribe to push notifications?';
    if (confirm(promptMessage)) {
      this.subscribe();
    }
  }
   public arrayBufferToBase64(buffer:any) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
  
}
