
  <div class="side-nav">
    <div class="top-logo" *ngIf="_commonService.isAdminUser();">
      <a  routerLink="/notification-management">
        <img class="large-logo" src="../assets/images/logo-white.svg" alt="">
        <img class="small-logo" src="../assets/images/logo-small.svg" alt="">
      </a>
    </div>
    <div class="top-logo" *ngIf="!_commonService.isAdminUser();">
      <a  routerLink="/notifications">
        <img class="large-logo" src="../assets/images/logo-white.svg" alt="">
        <img class="small-logo" src="../assets/images/logo-small.svg" alt="">
      </a>
    </div>
    <div class="left-menu">
      <ul>
        <li *ngIf="_commonService.CheckUserRole(NotificationsView)">
          <a routerLink="/notifications" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-notification.svg" alt="">
            <span>Notifications</span>
          </a>
        </li>
        <li  *ngIf="_commonService.CheckUserRole(UsersManagementView) || _commonService.CheckUserRole(UsersManagementCreate) || _commonService.CheckUserRole(UsersManagementEdit) ">
          <a routerLink="/user-management" routerLinkActive="active" (click)="menuClick()"> 
            <img src="../assets/images/icon-user-management.svg" alt="">
            <span>User Management</span>
          </a>
        </li>
        <li *ngIf="_commonService.CheckUserRole(NotificationsAdminEdit)">
          <a routerLink="/notification-management" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-notification-management.svg" alt="">
            <span>Notification Management</span>
          </a>
        </li>
        <li *ngIf="_commonService.CheckUserRole(NotificationsCreate)  || _commonService.CheckUserRole(NotificationsEdit)">
          <a routerLink="/my-notification" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-my-notification.svg" alt="">
            <span>My Notification</span>
          </a>
        </li>
        <li *ngIf="_commonService.CheckUserRole(GroupManagementCreate)  || _commonService.CheckUserRole(GroupManagementEdit) || _commonService.CheckUserRole(GroupManagementView)">
          <a routerLink="/group-management" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-group-management.svg" alt="">
            <span>Group Management</span>
          </a>
        </li>
        <li *ngIf="_commonService.CheckUserRole(RolesManagementCreate)  || _commonService.CheckUserRole(RolesManagementEdit) || _commonService.CheckUserRole(RolesManagementView)">
          <a routerLink="/role-management" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-role-management.svg" alt="">
            <span>Roles Management</span>
          </a>
        </li>
        <li *ngIf="_commonService.CheckUserRole(ContentApprovalEdit)  || _commonService.CheckUserRole(ContentApprovalOverrideApproval) || _commonService.CheckUserRole(ContentApprovalView)" >
          <a routerLink="/notification-request" routerLinkActive="active" (click)="menuClick()">
            <img src="../assets/images/icon-notification-request.svg" alt="">
            <span>Notification Requests</span>
          </a>
        </li>
      </ul>
    </div>
  </div>


<!-- <div class="side-nav">
  <div class="top-logo">
    <a  routerLink="/notification-management">
      <img class="large-logo" src="../assets/images/logo-white.svg" alt="">
      <img class="small-logo" src="../assets/images/logo-small.svg" alt="">
    </a>
  </div>
  <div class="left-menu">
    <ul>
      <li>
        <a routerLink="/notifications" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-notification.svg" alt="">
          <span>Notifications</span>
        </a>
      </li>
      <li>
        <a routerLink="/user-management" routerLinkActive="active" (click)="menuClick()"> 
          <img src="../assets/images/icon-user-management.svg" alt="">
          <span>User Management</span>
        </a>
      </li>
      <li>
        <a routerLink="/notification-management" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-notification.svg" alt="">
          <span>Notification Management</span>
        </a>
      </li>
      <li>
        <a routerLink="/my-notification" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-notification.svg" alt="">
          <span>My Notification</span>
        </a>
      </li>
      <li>
        <a routerLink="/group-management" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-group-management.svg" alt="">
          <span>Group Management</span>
        </a>
      </li>
      <li>
        <a routerLink="/role-management" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-role-management.svg" alt="">
          <span>Roles Management</span>
        </a>
      </li>
      <li>
        <a routerLink="/notification-request" routerLinkActive="active" (click)="menuClick()">
          <img src="../assets/images/icon-notification.svg" alt="">
          <span>Notification Requests</span>
        </a>
      </li>
    </ul>
  </div>
</div> -->