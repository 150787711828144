import { Component, ElementRef, Renderer2  } from '@angular/core';
import * as $ from 'jquery';
import { Role } from 'src/app/models/common.model';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  constructor(private el: ElementRef, private renderer: Renderer2,public _commonService: CommonService) {}
  
  isExpanded = false;

  NotificationsView=[Role.NotificationsView];
  NotificationsCreate=[Role.NotificationsCreate];
  NotificationsEdit=[Role.NotificationsEdit];
  NotificationsAdminEdit=[Role.NotificationsAdminEdit];

  ContentApprovalEdit=[Role.ContentApprovalEdit];
  ContentApprovalOverrideApproval=[Role.ContentApprovalOverrideApproval];
  ContentApprovalView=[Role.ContentApprovalView];

  UsersManagementCreate=[Role.UsersManagementCreate];
  UsersManagementEdit=[Role.UsersManagementEdit];
  UsersManagementView=[Role.UsersManagementView];

  RolesManagementCreate=[Role.RolesManagementCreate];
  RolesManagementEdit=[Role.RolesManagementEdit];
  RolesManagementView=[Role.RolesManagementView];

  GroupManagementCreate=[Role.GroupManagementCreate];
  GroupManagementEdit=[Role.GroupManagementEdit];
  GroupManagementView=[Role.GroupManagementView];

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  
  menuClick(){
		var w = window.innerWidth;
		if(w < 1200){
			// alert(w);
			$(".content-wrapper").removeClass("close-menu");
		}
		
	}
}
