import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './common.service';
import { Utils } from 'src/app/Utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { env } from 'process';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  totalRequests = 0;
  completedRequests = 0;
  constructor(public commonService: CommonService, private router: Router, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    this.totalRequests++;
    let authReq = request;
    const token = this.commonService.getToken();
    if (this.commonService.isAdminUser()) {
      // for admin user
      const adminUser = this.commonService?.getAdminUser() ?? "";
      authReq = request.clone({ headers: request.headers.set('userId', adminUser).set('isADD', "1").set('ConsistencyLevel','eventual') });
      //authReq = request.clone({ headers: request.headers });
    }
    else {
      // front user
      const username = this.commonService.getUser().userName ?? "";
      authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token).set('userId', username).set('isADD', "0") });

    }

    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      }),
      catchError((err) => {
        this.onEnd();
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            this.commonService.snackBarErrorMessage(Utils.checkNullValue(err.error.message));
          } else {
            let errorMsg = err?.error?.Message;
            if (err.status === 401) {
              //Unauthorized   
              //   this.commonService.snackBarErrorMessage(errorMsg);
              // redirect user to the logout pag
              if (!this.commonService.isAdminUser() && this.commonService.isTokenExpired()) {
                this.commonService.snackBarErrorMessage("Session has been expired, try to re-login");
                this.router.navigateByUrl(`/login`);
              } else {
                this.router.navigateByUrl(`/unauthorized`);
              }
            }
            if (err.status === 500) {
              //InternalServerEror
              this.commonService.snackBarErrorMessage(errorMsg);
            }
            if (err.status === 400) {
              //BadRequest
              if (err.error?.Message == 'userinactive') {
                this.commonService.snackBarErrorMessage("Sorry, your account is currently inactive. Please contact your administrator");
                this.router.navigateByUrl(`/login`);
              }
              else { this.commonService.snackBarErrorMessage(errorMsg); }
            }
            if (err.status === 404) {
              //NotFound
              this.commonService.snackBarErrorMessage(errorMsg);
            }
            if (err.status === 422) {
              //UnprocessableEntity
              this.commonService.snackBarErrorMessage(errorMsg);
            }
            if (err.status === 403) {
              //Forbidden
              //  this.commonService.snackBarErrorMessage("Access denied");
              this.router.navigateByUrl(`/unauthorized`);
            }
          }
        }
        return throwError(err);
      })
    )
  }

  private onEnd(): void {
    this.completedRequests++;
    if (this.completedRequests === this.totalRequests) {
      this.hideLoader();
      this.completedRequests = 0;
      this.totalRequests = 0;
    }
  }
  private showLoader(): void {
    this.spinner.show();
  }
  private hideLoader(): void {
    this.spinner.hide();
  }
}
