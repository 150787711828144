export const environment = {
    production: true,
    isAdmin: false,
    adminUrl:"admin.alda.avnet.com",
    apiURL:"https://api.alda.avnet.com/api/",
    clientId: '3668d3fe-d42e-421f-9660-b4b0ac0d4a63',
    tenantId: '8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    authority: 'https://login.microsoftonline.com/8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    redirectUri: 'https://admin.alda.avnet.com/login/callback',
    postLogoutRedirectUri: 'https://admin.alda.avnet.com/',
    grapAPI: 'https://graph.microsoft.com/v1.0/me',
    apiDomain: 'https://api.alda.avnet.com/',
    scopes: 'api://544cc9f9-62c5-4f5a-96bc-9b7bfbb86e8a/Logistic-api-access',
    perPageOptions: [5, 10, 20, 30, 50, 100],
    pageSize: 10,
    reSendOTPTimeInSecond:60,
    adminAccessGroupName:"avt-amer-prd-logistic-deskless-app-admin"
};