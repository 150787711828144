import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { RoleGuard } from './services/role-guard.service';
import { Role } from './models/common.model';
import { TermsConditionComponent } from './modules/shared/components/terms-condition/terms-condition.component';

// sets up routes constant where you define your routes
const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('src/app/modules/front/login/login.module')
  //       .then(m => m.LoginModule),
  // }, 
    // {
  //   path: '',
  //   redirectTo:'login',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('src/app/modules/front/login/login.module')
        .then(m => m.LoginModule),
  },
  {
    path: 'user-registration',
    loadChildren: () =>
      import('src/app/modules/front/user-registration/user-registration.module').then((x) => x.UserRegistrationModule),
  },
  {
    path: 'user-profile/:username/edit',
    loadChildren: () =>
      import('src/app/modules/front/user-profile/user-profile.module').then((x) => x.UserProfileModule),
  },
  {
    path: 'user-management',
    //  canActivate: [RoleGuard],
    //  data: [Role.UsersManagementView],
    loadChildren: () =>
      import('src/app/modules/admin/user-management/user-management.module').then((x) => x.UserManagementModule),
  },
  {
    path: 'group-management',
    loadChildren: () =>
      import('src/app/modules/admin/group-management/group-management.module').then((x) => x.GroupManagementModule),
  },
  {
    path: 'role-management',
    // canActivate: [RoleGuard],
    // data: [Role.RolesManagementCreate,Role.RolesManagementEdit,Role.NotificationsView,Role.RolesManagementEdit],
    loadChildren: () =>
      import('src/app/modules/admin/role-management/role-management.module').then((x) => x.RoleManagementModule),
  },
  {
    path:'notifications',
    canActivate: [RoleGuard],
    data: [Role.NotificationsView],
    loadChildren: () =>
      import('src/app/modules/front/notifications/components/list/notifications.module').then((x) => x.NotificationsModule),
  },
  {
    path: 'notification-management',
    canActivate: [RoleGuard],
    data: [Role.NotificationsAdminEdit],
    loadChildren: () =>
      import('src/app/modules/admin/notification-management/notification-management.module').then((x) => x.NotificationManagementModule),
  },
  {
    path: 'my-notification',
    canActivate: [RoleGuard],
    data: [Role.NotificationsCreate,Role.NotificationsEdit],
    loadChildren: () =>
      import('src/app/modules/front/user-notification/user-notification.module').then((x) => x.UserNotificationModule),
  },
  {
    path: 'notification-request',
    canActivate: [RoleGuard],
    data: [Role.ContentApprovalEdit,Role.ContentApprovalOverrideApproval,Role.ContentApprovalView],
    loadChildren: () =>
      import('src/app/modules/front/notification-request/notification-request.module').then((x) => x.NotificationRequestModule),
  },
  {
    path:'view-notifications',
    canActivate: [RoleGuard],
    data: [Role.NotificationsView,Role.NotificationsAdminEdit,Role.NotificationsCreate,Role.NotificationsEdit],
    loadChildren: () =>
      import('src/app/modules/front/notifications/components/view/view-notifications.module').then((x) => x.ViewNotificationsModule),
  },
  {
    path: 'unauthorized',
    component :UnauthorizedComponent
  },
  {
    path: 'terms-condition',
    component :TermsConditionComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
