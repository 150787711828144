import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TableDialogColumn, TableDialogData } from 'src/app/models/common.model';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.css']
})
export class TableDialogComponent<T> implements OnInit {

  title: string;
  columns: Array<TableDialogColumn>;
  displayColumns: Array<string> = [];
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();

  constructor(@Inject(MAT_DIALOG_DATA) private data: TableDialogData<T>) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.columns = this.data.columns;
    this.displayColumns = this.columns.map(ele => ele.name);
    this.dataSource.data = this.data.data;
  }
}
