import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-push-confirm-dialog',
  templateUrl: './push-confirm-dialog.component.html',
  styleUrls: ['./push-confirm-dialog.component.css']
})
export class PushConfirmDialogComponent {
  message: string = "Are you sure want to delete?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<PushConfirmDialogComponent>,private common: CommonService) {
      if(data){
          this.message = data.message || this.message;
          if (data.buttonText) {
              this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
              this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
          }
      }
  }

  onConfirmClick(): void {
      this.dialogRef.close(true);
  }
  onCheckboxChange(e: any, st: string) {
    switch (st) {
      case 'dontaskagain':
        if(e.checked)
        {
          this.common.hideSubScribeDialog();
        }else{
          this.common.RemoveSubScribeDialogKey();
        }
        break;
      // case 'sms':
      //   this.smsAllowed = e.checked;
      //   break;
      // case 'push':
      //   this.pushAllowed = e.checked;
      //   break;
      default:
        break;
    }
  }

}
