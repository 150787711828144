import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private channel = new BroadcastChannel('my-channel');

  sendMessage(message: any) {
    this.channel.postMessage(message);
  }

  addMessageListener(callback: (message: any) => void) {
    this.channel.addEventListener('message', (event) => {
      callback(event.data);
    });
  }
}