import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn=false;
  username?: string;

  constructor( public common:CommonService,
    private _authService: MsalService,private router: Router){}

  ngOnInit(): void {
    const user = this.common.getUser()
    this.username =user.firstName +' '+user.lastName;

  }

  logout() {   
    window.localStorage.clear(); 
    this._authService.logoutRedirect();
  }
  frontLogout() {  
    this.redirectLogin(); 
  }
  
  
  private redirectLogin() {
    this.router.navigateByUrl('login');
  }

  myFunction() {
    var element = document.getElementById("myDIV");
    element?.classList.toggle("close-menu");
  }
}
