<div class="container">
    <h1 mat-dialog-title class="text-center">{{dialogData.title}}</h1>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-12 form-group">
                <mat-form-field>
                    <mat-select [placeholder]="dialogData.placeholder" [formControl]="statusCtrl"
                        (selectionChange)="setSelectedStatus($event.value)">
                        <mat-option value="">{{'--' + dialogData.placeholder + '--'}}</mat-option>
                        <mat-option *ngFor="let status of dialogData.data" [value]="status">
                            {{status.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button class="btn btn-avnet btn-large ms-3 me-3" tabindex="1" (click)="submit()">{{dialogData.ok}}</button>
    <button mat-raised-button mat-dialog-close class="btn btn-avnet-outline btn-large "
    tabindex="-1">{{dialogData.cancel}}</button>
</div>