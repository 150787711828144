    <!-- <div  *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS'" >
      <div class="alert alert-secondary m-2">
        <button type="button" class="btn-close position-absolute top-0 end-0 m-1" aria-label="Close" (click)="closePwa()"></button>

        <button class="push-subscriber" *ngIf="modalPwaPlatform === 'ANDROID'"  (click)="addToHomeScreen()">
          Add this WEB app to home screen
        </button>
         <button class="push-subscriber" *ngIf="modalPwaPlatform === 'IOS'"  (click)="addToHomeScreen()">
          Add this WEB app to home screen
        </button> 

      </div>
    </div> -->


    <div class="accept-cookies" *ngIf="(modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS') && !hideContetntdiv && !common.isHidePWD()">
      <div class="cookies-content">
          <div >
            Install the PWA today and enjoy a faster, more convenient way to explore and use our app.
          </div>
          <div class="cookies-content-right">
              <button class="btn btn-avnet me-3" (click)="addToHomeScreen();"> Install</button>
              <button class="btn btn-avnet-outline" (click)="closePopup()">Decline</button>
          </div>
      </div>
  </div>