<div class="login-bg">
    <div class="login-box">
        <div class="login-logo">
            <a href="javascript:void(0)">
                <img src="../assets/images/logo-black.svg" alt="">
            </a>
        </div>
        <div class="login-form registration-form">

            <h3 style="text-align: center;color: black;"><b>Terms of Use</b></h3>
            
            <p>Welcome to Avnet Workplace Communicator! These Terms of Use govern your access to and use of the Avnet Workplace Communicator platform (“App”). By accessing or otherwise using the App, you agree to be bound by these Terms of Use and all Avnet policies applicable to your use of the App. Please read them carefully before using the App.  TO BE CLEAR, USE OF THE APP IS ENTIRELY VOLUNTARY AND THE INFORMATION SHARED ON THE APP WILL OTHERWISE BE MADE AVAILABLE TO YOU VIA DIFFERENT CHANNELS IF YOU CHOOSE NOT TO USE THE APP.  If you do not agree to these Terms of Use, do not use the app.  </p>

            <h5>1. Definitions. </h5>
            <p>a. <b>App</b> means Avnet Workplace Communicator.</p> 
            <p>b. <b>Privacy Policy</b> means Avnet’s Global Data Privacy Policy, available at: <a href="https://www.avnet.com/wps/portal/us/about-avnet/data-privacy-policy/">Privacy Policy.</a> </p>
            <p>c. <b>Terms</b> refers to the Terms of Use contained herein.</p>
            <p>d. <b>User</b> means any individual who accesses or uses the App.</p>
            
            <h5>2. Acceptance of Terms.</h5>
            <p>By accessing or using the App, you agree to be bound by these Terms of Use and all Avnet policies applicable to your use of the App. If you do not agree to these terms, please do not use the App.</p>
            
             <h5>3. License and Access. </h5>
             <p>a. Use of the App is intended only for Avnet employees, contractors, and authorized representatives, if you do not fall into one of the preceding categories, do not use the App. </p>
             <p>b. You acknowledge and agree that use of the App is optional and not required for your continued employment at Avnet. You agree to not use the App outside of your scheduled working hours.</p>
             <p>c. You acknowledge that your use of the App is subject to all applicable Avnet policies, including but not limited to Avnet’s Privacy Policy, and you agree to comply with such policies. </p>
             <p>d. Avnet grants you a limited, non-exclusive, non-transferable, and revocable license to use the App during your continued employment or engagement with Avnet,  subject to these Terms of Use and in accordance with all applicable laws and regulations. You may not copy, modify, distribute, sell, or lease any part of the App, nor may you reverse engineer or attempt to extract the source code of the App. </p>
             
             <h5>4. Responsibility for Messaging and Data Rates. </h5>
             <p>As pertains to use of the App, except as required by law, you are responsible for all costs and expenses associated with your personal device(s), including any fees, including messaging and data rates, that may be charged by your mobile carrier or internet service provider in connection with their use of the App</p>
             
             <h5>5. Confidentiality. </h5>
             <p>You acknowledge that the information transmitted through the App may include confidential or proprietary business information or trade secrets belonging to Avnet or its business partners (e.g. strategic business plans, supplier and customer requirements, technical information about products and services we provide, pricing structure, costs, and other financial information) (collectively the “Confidential Information”). You agree to maintain the confidentiality of such Confidential Information and not disclose it to any third party without Company's prior written consent, unless required by law. This obligation of confidentiality survives the termination of these Terms of Use or your cessation of using the App. In addition, nothing in these Terms in any way prohibits or is intended to restrict or impede, and shall not be interpreted or understood as restricting or impeding, you from: (a) exercising your rights under Section 7 of the National Labor Relations Act (NLRA), including your rights to discuss wages, hours, and other terms and conditions of employment; or (b) otherwise disclosing or discussing truthful information about unlawful employment practices, including unlawful discrimination, harassment, retaliation, or sexual assault.</p>
             
             <h5>6. Privacy. </h5>
             <p>Your use of the App is subject to Avnet's <a href="https://www.avnet.com/wps/portal/us/about-avnet/data-privacy-policy/">Privacy Policy.</a> as modified from time to time, available at Privacy Policy or accessible through Avnet’s Policy Hub. By using the App, you consent to the terms of the Privacy Policy.  Accordingly, you should have no reasonable expectation of privacy with respect to any information that is transmitted with the App.</p>

             <h5>7. Content. </h5>
             <p>a. Notwithstanding the limited license grant included herein, Avnet retains all rights, title, and interest in and to the App and any derivatives thereof, including all intellectual property rights. </p>
             <p>b. Additionally, by posting or otherwise submitting or inputting content in the App, you grant Avnet a non-exclusive, royalty-free, worldwide, perpetual license to use, modify, and distribute your content within the App for Avnet’s business operations.</p>

             <h5>8. Prohibited Activities. </h5>
             <p>You agree to not use the App for any unlawful purpose or in any way that violates these Terms, the Code of Conduct, the Global Information Security Policy, and any applicable local work rules or policies, including, but not limited to, polices against harassment, discrimination, and retaliation. </p>

             <p>Prohibited activities include, but are not limited to:</p>
             <ul>
                <li>Transmitting any harmful or malicious code.</li>
                <li>Attempting to interfere with the proper functioning of the App. </li>
                <li>Attempting to gain unauthorized access to any portion of the App. </li>
                <li>Attempting to disrupt, damage, or impair Avnet or its products or services</li>
                <li>Sharing Confidential Information received through the App with unauthorized individuals.</li>
                <li>Transmitting any CUI, ITAR or other information that may only be shared via a secure platform.</li>
                <li>Discriminatory remarks, harassment, bullying, and threats of violence in violation of Avnet policies. </li>
                <li>Statements, photographs, video, or audio that reasonably could be viewed as malicious, obscene, threatening, or intimidating, that disparage customers, coworkers, or suppliers, or that might constitute harassment or bullying. Examples of such conduct might include offensive posts meant to intentionally harm someone’s reputation or posts that could contribute to a hostile work environment on the basis of race, sex, disability, religion, or any other status protected by law or company policy. </li>
             </ul>
            
             <h5>9. Use of the App. </h5>
             <p>You warrant and represent that you will use the App responsibly and only during the course of your continued employment or engagement with Avnet. You agree to adhere to company policies and guidelines, and all applicable laws and regulations, while using the App. You are responsible for maintaining the confidentiality of login credentials associated with your account. You agree to notify Avnet immediately of any unauthorized access to or use of your account. </p>
             
             <h5>10. Disclaimer of Warranties. </h5>
             <p>The App is provided "as is" and Avnet makes no warranties regarding its functionality, reliability, or availability. Without limiting the forgoing and for the avoidance of doubt, Avnet does not warrant that the App will be error-free or uninterrupted</p>

             <h5>11. Limitation of Liability. </h5>
             <p>To the fullest extent permitted by law, Avnet shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of your use of the App.</p>

             <h5>12. Modification of Terms. </h5>
             <p>We reserve the right to modify these Terms at any time, and changes to these Terms are effective immediately upon posting. It is your responsibility to review these Terms periodically. Updated terms will be posted within the App, and your continued use of App after such modifications constitutes acceptance of the revised terms.</p>

             <h5>13. Termination. </h5>
             <p>Avnet may terminate or suspend your access to the App at our sole discretion, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your rights to access and use the App cease immediately and you will remove the App from all of your personal devices on which it is installed </p>
             
             <h5>14. Governing Law. </h5>
             <p>Your use of the App and these Terms of Use shall be governed by and construed in accordance with the laws of Arizona, excluding its conflict of law principles. You acknowledge and agree that a State or Federal court located in Phoenix, Arizona shall have exclusive jurisdiction and venue to adjudicate any and all disputes arising out of or in connection with your use of the App or these Terms of Use. You consent to the exercise by such courts of personal jurisdiction over you and waive any objection it might otherwise have to venue, personal jurisdiction, inconvenience of forum, and any similar or related doctrine.</p>

             <h5>15. Entire Agreement. </h5>
             <p>These Terms of Use constitute the entire agreement between you and Avnet regarding the subject matter herein and supersedes all prior or contemporaneous agreements, representations, warranties, and understandings.</p>

             <h5> 16. Contact Us. </h5>
             <p>If you have any questions or concerns about these Terms or policies applicable to your use of the App, please contact your supervisor or human resources (HR) business partner.   Similarly, you are encouraged to report any employee using the App in violation of these Terms or in violation of Avnet policies.</p>
             
             <p>By using the App, you acknowledge that you have read and understood these Terms of Use and our Privacy Policy and agree to be bound by them.</p>
             <ul>
               <li>My use of this App is optional and voluntary, and if I choose to use the App, I may be charged for standard messaging and data rates for which I am solely responsible and will not be reimbursed. </li>
               <li>My use of this App is governed by these Terms. </li>
               <li>I shall only use this App during my regularly scheduled work hours and will inform my supervisor if I was required to use the App outside of my scheduled working hours. </li>
             </ul>

            <div class="col-md-12 text-center">
                <div class="mt-3 font-14">
                    <a onclick="window.history.back()"  class="btn btn-avnet btn-large">Back</a>
                </div>
            </div>
        </div>
    </div>
</div>