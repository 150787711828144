import { Component, HostListener, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-pwa-installation-alert',
  templateUrl: './pwa-installation-alert.component.html',
  styleUrls: ['./pwa-installation-alert.component.css']
})
export class PwaInstallationAlertComponent implements OnInit {
  title = 'ALDA';

  isOnline: boolean;
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;
  hideContetntdiv: boolean;

  constructor(private platform: Platform, private swUpdate: SwUpdate,
    private swPush: SwPush, public common: CommonService) {
    this.isOnline = false;
    this.modalVersion = false;
  }

  ngOnInit(): void {

    this.updateOnlineStatus();

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        const prompt = confirm('A new version is available. Reload the application to update?');
        if (prompt) {
          window.location.reload();
        }
      });

      // this.swUpdate.versionUpdates.pipe(
      //   filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      //   map((evt: any) => {
      //     console.info(`currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`);
      //     this.modalVersion = true;
      //   }),
      // );
    }

    this.loadModalPwa();
  }


  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    console.info(`isOnline=[${this.isOnline}]`);
  }

  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  private loadModalPwa(): void {

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        // event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

    if (this.platform.IOS) {

      window.addEventListener('beforeinstallprompt', (event: any) => {
        const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
        if (!isInStandaloneMode) {
          this.modalPwaPlatform = 'IOS';
        }
        this.modalPwaEvent = event;
      });
    }

    // alert massage code here
    // if (this.modalPwaPlatform==='ANDROID' || this.modalPwaPlatform==='IOS' ){
    //   const prompt = confirm('Add this WEB app to home screen?');
    //   if (prompt) {
    //     this.addToHomeScreen();
    //    // window.location.reload();
    //   }
    // }



  }


  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }

 public closePopup() :void {
    this.common.hidePWA();
    this.hideContetntdiv = true;
  }

}
