import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusDialogData } from 'src/app/models/common.model';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnInit, AfterViewInit {

  dialogData: StatusDialogData;
  statusCtrl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: StatusDialogData,
    private dialogRef: MatDialogRef<StatusDialogComponent>) { }

  ngOnInit(): void {
    this.dialogData = this.data;
  }

  ngAfterViewInit() {
    this.statusCtrl.setValue(this.dialogData.selected);
  }

  setSelectedStatus(e: KeyValue<number, string>) {
    this.data.selected = e;
  }

  submit(): void {
    this.dialogRef.close(this.data.selected);
  }
}
