import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  // @Input() isLoading:any;
  
  // constructor(private spinner: NgxSpinnerService) {}

  // ngOnChanges(changes: any): void {
  //   if(changes.isLoading.currentValue) {
  //     this.spinner.show();
  //   } else {
  //     this.spinner.hide();
  //   }
  // }
}
