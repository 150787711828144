import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export class Utils {

    public static checkNullValue(strVal: any) {
        //return (strVal == null) ? "" : strVal.toString();
        if (strVal == null || typeof strVal === 'undefined') {
            return "";
        } else {
            return strVal.toString();
        }
    }

    public static AllowNumberAndSpaceOnly(event: any) {
        var e = event;
        var charCode;

        if (window.event) // IE
        {
            charCode = e.keyCode;
        }
        else if (e.which) // Safari 4, Firefox 3.0.4 
        {
            charCode = e.which
        }

        if (charCode == 32)
            return true;
        else if (charCode < 48 || charCode > 57)
            return false;

        return true;
    }

    public static isResponseSuccessfull(response: any): boolean {
        if (Utils.checkNullValue(response) != '') {
            if (Utils.checkNullValue(response['type']) == 'Success') {
                return true;
            }
        }
        return false;
    }

    public static isCheckListNull(val: any): any[] {
        if (val == null || val == undefined) {
            return [];
        }
        return val;
    }

    public static noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isWhitespace = (control.value || '').trim().length === 0;
            return isWhitespace ? { whitespace: true } : null;
        };
    }



    public static textFilterParams = {
        // trimInput: true,
        buttons: ['reset', 'apply'],
        // buttons: ['reset', 'apply','clear'],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
        //filterOptions: ['equals', 'notEqual', 'startsWith', 'endsWith', 'in', 'like', 'notLike'],
    }

    public static getFilter(data: any) {
        let filterValue;
        switch (data) {
            case "equals":
                filterValue = 'eq';
                break;
            case "notEqual":
                filterValue = 'new';
                break;
            case "startsWith":
                filterValue = 'startsWith';
                break;
            case "endsWith":
                filterValue = 'endsWith';
                break;
            case "contains":
                filterValue = 'contains';
                break;
            case "notContains":
                filterValue = 'notContains';
                break;
            case "lessThan":
                filterValue = 'le';
                break;
            case "greaterThan":
                filterValue = 'ge';
                break;
        };
        return filterValue
    }

}