<!-- 
<section>
    <div class="header-background">Angular Skeleton
        <a class="header-links" routerLink="/" routerLinkActive="active" ariaCurrentWhenActive="page">Dashboard</a>
        <a class="header-links" routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">Products</a>
    </div>
</section> -->

<header>
    <div class="left-header">
        <a href="javascript:void(0)" (click)="myFunction()" >
            <img src="../assets/images/icon-toggle.svg" alt="">
        </a>
    </div>
    <div class="mobile-logo-white">
        <a href="javascript:void(0)">
            <img src="../assets/images/logo-white.svg" alt="">
        </a>
       
    </div>  
    <div class="right-header">
        <div class="dropdown">
            <a class="btn btn-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="welcome">Welcome</span>
              <span class="profile-username">{{ common.isAdminUser() ? common.getFirstLastName() :common.getUser().firstName +" "+common.getUser().lastName}}</span>
              <!-- <span class="profile-username">{{ common.getUser().firstName +" "+common.getUser().lastName}}</span> -->
              <span class="mobile-user-icon">
                <img src="../assets/images/user-mobile-icon.png" alt="">
              </span>
            </a>
          
            <ul class="dropdown-menu">
              <li class="mobile-username">  
                <span class="welcome">Welcome</span>
                 <span class="profile-username">{{ common.isAdminUser() ? common.getFirstLastName() :common.getUser().firstName +" "+common.getUser().lastName}}</span>
              </li>
              <li *ngIf="!common.isAdminUser()"><a class="dropdown-item" [routerLink]="['user-profile/'+common.getUser().userName + '/edit']">My Profile</a></li>
              <li *ngIf="!common.isAdminUser()"><a class="dropdown-item" style="cursor: pointer;"  (click)="frontLogout()">Logout</a></li>
              <li *ngIf="common.isAdminUser()"><a class="dropdown-item" style="cursor: pointer;"  (click)="logout()">Logout</a></li> 
            
            </ul>
          </div>
    </div>
</header>