import { Component, HostListener, SimpleChanges } from '@angular/core';
import { CommonService } from './services/common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BroadcastService } from './services/BroadcastService ';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '';
  isLoggedIn = false;
  isFistLoad = true;
  routerEvents: any;
  constructor(public common: CommonService,
    private route: Router, private titleService: Title, private broadcastService: BroadcastService) { }

  ngOnInit(): void {
    console.log(window.location.hostname);
    // if logout button fire then logout from all open browser tab
    this.broadcastService.addMessageListener((message) => {
      if (message.event === 'custom-event') {
        this.route.navigate(['login']);
      }
    });

    if (this.common.isAdminUser()) {
      this.route.navigate(['login']);
      this.title = 'ALDA[Admin]';
      this.titleService.setTitle('Logistic Deskless APP[Admin]');
      if (this.common.getAdminUser() == null) {
        this.routerEvents = this.route.events.subscribe(
          (event: any) => {
            if (event instanceof NavigationEnd) {
              if (event.url == '/') { this.route.navigate(['login']); }
            }
          }
        )
      } else {
        if (this.common.isAdminAccess()) {

        } else {
          this.common.clearRole();
        }
      }
    }
    else {
      if (this.common.getToken()) {
        this.isLoggedIn = true;
        this.routerEvents = this.route.events.subscribe(
          (event: any) => {
            if (event instanceof NavigationEnd) {
              if (event.url == '/') { this.route.navigate(['notifications']); }

            }
          }
        )
      } else {
        this.routerEvents = this.route.events.subscribe(
          (event: any) => {
            if (event instanceof NavigationEnd) {
              if (event.url == '/') { this.route.navigate(['login']); }

            }
          }
        )
      }


    }
  }
}

