import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _commonService: CommonService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const routeRole = next.data;
    const userRoles = this._commonService.getRoleList();
    // check for admin portal access
     if(this._commonService.isAdminUser() && !this._commonService.isAdminAccess())
      {
        this._commonService.clearRole();
        this.router.navigateByUrl(`/unauthorized`);
      }
    const routData = Object.values(routeRole)
    var isauthorize = false;
    if (userRoles?.length > 0) {
      for (const v of routData) {
        if (userRoles.filter((item: any) => item.roleAccess == v).length > 0) {
          isauthorize = true;
          break
        }
      }
    }
    if (isauthorize) {
      return true;
    }
    else {
      if (!this._commonService.IsLoggedIn()) {
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return true;
      } else {
        this.router.navigateByUrl(`/unauthorized`);
        return true;
      }
    }

  }
}
